@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i&display=swap');

.trello-login-button {
    font-family: "jaf-facitweb", "Noto Sans", -apple-system, "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #E2E4E6;
    border-radius: .4em;
    color: hsl(0, 0%, 30%);
    display: inline-block;
    font-size: 1.2em;
    line-height: 1.1em;
    padding: .7em 1.3em;
    text-decoration: none;
    vertical-align: top;
    font-weight: bold;
    cursor: pointer;
    border: 0;
    outline: 0;
}

.trello-login-button:visited {
    color: hsl(0, 0%, 30%);
}

.style-metamorph {
    box-shadow: 0 2px 0 #CDD2D4;
}

.style-metamorph.color-green {
    background: -webkit-gradient(linear, left top, left bottom, from(#61BD4F), to(#5AAC44));
    background: linear-gradient(to bottom, #61BD4F 0%, #5AAC44 100%);
    box-shadow: 0 2px 0 #3F6F21;
    color: #fff;
}

.style-metamorph.color-green:hover,
.style-metamorph.color-green:focus {
    background: -webkit-gradient(linear, left top, left bottom, from(#5AAC44), to(#519839));
    background: linear-gradient(to bottom, #5AAC44 0%, #519839 100%);
}

.style-flat {
    border: 1px solid transparent;
    outline: 0;
}

.style-flat.color-green {
    background: #61BD4F;
    border-color: #61BD4F;
    color: #fff;
}

.style-flat.color-green:hover {
    background: #50a73f;
    border-color: #4b9e3b;
}

.style-flat.color-green:focus {
    background: #50a73f;
    border-color: #4b9e3b;
    box-shadow: 0 0 0 0.2rem rgba(97, 189, 79, 0.5);
}

.style-flat.color-grayish-blue {
    background: #6D8EA2;
    border-color: #6D8EA2;
    color: #fff;
}

.style-flat.color-grayish-blue:hover {
    background: #5b7b8e;
    border-color: #567486;
}

.style-flat.color-grayish-blue:focus {
    box-shadow: 0 0 0 0.2rem rgba(109, 142, 162, 0.5);
}

.style-flat.color-light {
    background: #EBECF0;
    border-color: #EBECF0;
    color: #212529;
}

.style-flat.color-light:hover {
    background: #d5d7e0;
    border-color: #ced0da;
}

.style-flat.color-light:focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 236, 240, 0.5);
}